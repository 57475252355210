import React, {Fragment, useCallback, useEffect, useState} from 'react';
import Spinner from 'react-activity/dist/Spinner';
import {useDispatch, useSelector} from 'react-redux';
import {Brand} from '../../constants/Constants';
import iconClose from '../../img/icon-close.svg';
import {
    sAccountBrands,
    sDeletedAccount,
    sLoadingDeletedAccount,
    sUserCurrent,
} from '../../reducers/selectors';
import {deleteAccount} from '../../stores/AppAction';

function DeleteAlert({show, setshow}) {
    const dispatch = useDispatch();
    const user = useSelector(sUserCurrent);
    const brands = useSelector(sAccountBrands);
    const loadDelete = useSelector(sLoadingDeletedAccount);
    const accountDeleted = useSelector(sDeletedAccount);
    const [brandsToDelete, setBrandsToDelete] = useState([]);

    useEffect(function() {
        if (accountDeleted) {
            setTimeout(function() {
                window.location.reload();
            }, 5000);
        }
    }, [accountDeleted]);

    const handleCheckBrand = useCallback(function(event) {
        event.target.checked ?
            setBrandsToDelete([...brandsToDelete, event.target.value]) :
            setBrandsToDelete(brandsToDelete.filter((brand) => {
                return brand != event.target.value;
            }));
    }, [setBrandsToDelete, brandsToDelete]);

    const handleDelete = useCallback(function(e) {
        e.preventDefault();
        dispatch(deleteAccount(brandsToDelete));
    }, [brandsToDelete]);
    return (
        <div style={{display: show ? 'block' : 'none'}} className={'container-wrapper'}>
            <div style={{display: show ? 'grid' : 'none'}}
                 className="container alert">
                <div className="alert-container delete-alert">
                    <img onClick={() => setshow()} src={iconClose}
                         alt="icona chiudi" className="icon-close" />
                    <h3>Completa la richiesta di cancellazione</h3>
                    <div className="text-alert">
                        <p>
                            Facendo seguito alla sua richiesta di cancellazione
                            relativa a uno o più Brand da Lei selezionati, Le
                            comunichiamo che non tratteremo ulteriormente i suoi
                            dati relativamente a quel Brand, nè a fini di
                            marketing/profilazione, nè ai fini della sua partecipazione
                            alle manifestazioni a premio riservate agli iscritti.
                            Come conseguenza di ciò,  le segnaliamo che verrà azzerato
                            il punteggio di eventuali raccolte punti a cui stesse partecipando,
                            fatti in ogni caso salvi eventuali premi già richiesti,
                            e che il suo nominativo non verrà considerato ai fini
                            di eventuali estrazioni finali.

                            <br /><br />
                            La informiamo che i
                            suoi dati verranno in ogni caso conservati ai fini di
                            consentire alle competenti autorità l’effettuazione delle
                            verifiche legate allo svolgimento di tali manifestazioni
                            a premio, e comunque fino al decorso dei termini di
                            prescrizione previsti dalla legge, ai soli fini di consentire
                            alla nostra società di difendere i propri diritti.

                            <br /><br />
                            Sono stati individuati i seguenti account
                            legati
                            all’indirizzo e-mail:<br />
                            <span>{user && user.email}</span>
                            <br /><br />

                            Seleziona quelli che desideri eliminare e clicca sul
                            bottone.
                        </p>
                        <form className="account-selection">
                            {brands && brands.map(function(br, i) {
                                const brand = Object.keys(Brand);
                                // TODO: Search Hero
                                return (
                                    <React.Fragment key={br}>
                                        <div key={br} className="input-box error">
                                            <input onChange={handleCheckBrand}
                                                   value={br} type="checkbox"
                                                   name="unsubscribe" id={`delete-${br}`} />
                                            <span className="check" />
                                            <label htmlFor={`delete-${br}`}>{Brand[br]}</label>
                                        </div>
                                        {(br === 'pampers' && brandsToDelete.includes('pampers')) && (
                                            <div>
                                                <p>
                                                    <span style={{fontSize: '0.8em'}}>
                                                        Attenzione ti ricordiamo che richiedendo la cancellazione
                                                    al Club Coccole Pampers verranno cancellati anche eventuali
                                                    consensi dati ai Marchi:
                                                        {!brands.includes('lines') && ` ${Brand.lines},`}
                                                        {!brands.includes('ace') && ` ${Brand.ace},`} {Brand.hero}, {Brand.maternity}, iMamma e a tutti i Partner di Fater
                                                    </span>
                                                </p>
                                            </div>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                            {accountDeleted &&
                            <span className="text-alert">
                                La richiesta è stata presa in carico.
                                {brandsToDelete.length === brands.length && <>
                                <br />Verrà effettuato un logout automatico.
                                </>}
                            </span>}
                            {loadDelete &&
                            <div className={'main-btn red center-spinner'}>
                                <Spinner size={1} color="#ffffff"
                                         class={'rai-spinner white'} animating
                                         speed={0.5}
                                         style={{
                                             height: '30px',
                                             width: '30px',
                                         }} />
                            </div>}
                            {(!accountDeleted && !loadDelete &&
                                brandsToDelete.length > 0) &&
                            <button onClick={handleDelete} type="submit"
                                    className="main-btn red">Elimina</button>}
                        </form>
                    </div>
                </div>
            </div>
            <div style={{display: show ? 'block' : 'none'}}
                 className={'alert-bg'} />
        </div>
    );
}

export default DeleteAlert;
