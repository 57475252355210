import React, {Fragment, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Brand, Informative} from '../constants/Constants';
import iconClose from '../img/icon-close.svg';
import {
    sAccountBrands, sActiveBrand, sBrandsAccepted,
    sPreferences,
    sShowModalAcceptedTerms, sShowModalNotEditableConsent,
} from '../reducers/selectors';
import {
    acceptBrand,
    acceptTerms,
    getPreferences,
    toggleAcceptTermsModal, toggleNotEditableTermsModal,
} from '../stores/AppAction';

export default function NotEditableContentModal() {
    const dispatch = useDispatch();
    const show = useSelector(sShowModalNotEditableConsent);
    const handleHideModal = useCallback(() => {
        dispatch(toggleNotEditableTermsModal());
    }, [dispatch]);

    if (!show) return null;
    return (
        <Fragment>
            <div className="container alert">
                <div className="alert-container terms">
                    <img src={iconClose} onClick={handleHideModal}
                         alt="icona chiudi" className="icon-close" />
                    <h3>Consenso iMamma</h3>
                    <p>
                        Il consenso è modificabile secondo le modalità indicate nell'<a href="https://share.imamma.link/informativa" target="_blank">informativa iMamma</a>
                    </p>
                    <button onClick={handleHideModal} className="main-btn">
                        OK
                    </button>
                </div>
            </div>
            <div className={'alert-bg'} />
        </Fragment>
    );
}
