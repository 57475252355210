import {useSelector} from 'react-redux';
import usrPic from '../img/icon-brand.svg';
import usrPicLines from '../img/icon-brand-lines.svg';
import usrPicAce from '../img/icon-brand-ace.png';
import iconUser from '../img/icon-add-account.svg';
import chevron from '../img/icon-chevron.svg';
import {sUserCurrent} from '../reducers/selectors';

function UserAccounts() {
    const user = useSelector(sUserCurrent);
    // function openAccounts(e){
    //     e.preventDefault();
    //     var box = document.getElementById("accounts-box");
    //     var chev = document.getElementById("chevron");
    //
    //     if (box.classList.contains("active")) {
    //         chev.classList.remove("active");
    //         box.classList.remove("active");
    //     } else {
    //         chev.classList.toggle("active");
    //         box.classList.toggle("active");
    //     }
    // }

    return (
        <div className="user-profile">
            <img src={usrPic} className="user-picture" alt="icona account" />
            <div className="user-info">
                <p className="email">{user && user.email}</p>
                <p className="name">{(user && user.name !== undefined) && user.name} </p>
            </div>

            {/*<img src={chevron} className="icon-chevron" id="chevron" onClick={openAccounts} alt="freccia" />*/}

            {/*<div className="accounts-box" id="accounts-box">*/}
            {/*    <div className="accounts">*/}
            {/*        <div className="account-profile">*/}
            {/*            <img src={usrPicLines} className="user-picture" alt="icona profilo lines" />*/}
            {/*            <div className="account-info">*/}
            {/*                <p className="email">tim.jennings@example.com</p>*/}
            {/*                <p className="account-type"> Account LINES</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="account-profile">*/}
            {/*            <img src={usrPicAce} className="user-picture" alt="icona profilo ace" />*/}
            {/*            <div className="account-info">*/}
            {/*                <p className="email">sara.cruz@example.com</p>*/}
            {/*                <p className="account-type">Account Ace</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <button className="add-account">*/}
            {/*            <div className="icon-add-account">*/}
            {/*                <img src={iconUser} className="user-picture" alt="icona aggiungi account" />*/}
            {/*            </div>*/}
            {/*            Aggiungi un altro account*/}
            {/*        </button>*/}
            {/*    </div>*/}
            {/*    <button className="logout"> Esci da tutti gli account</button>*/}
            {/*</div>*/}
        </div>
    );
}

export default UserAccounts;