import Header from './components/Header';
import Footer from './components/Footer';

function NoteLegali() {

    return (
        <div className="informations">
            <Header />
            <div id="overlay" className="overlay-menu"></div>
            <div className="top-section">
                <div className="container">
                    <div className="title-text">
                        <h1>Note Legali</h1>
                        <h1 className="subtitle">Informazioni ai sensi dell’art. 2250 Codice civile</h1>
                        <p>
                            Ragione sociale: Fater S.p.a.<br />
                            Sede legale: Via Mare Adriatico, n. 122 (cap 65010) - Spoltore<br />
                            Partita IVA IT 01282360682<br />
                            Telefono: +39 085 3551111<br />
                            PEC: <a href="#">fater@pec-fater.it </a><br />
                            Sito istituzionale: <a href="#">www.fatergroup.com</a><br />
                        </p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="content">
                    <div className="information-item">
                        <h1 className="title">Contenuti veicolati mediante strumenti di comunicazione elettronica</h1>
                        <p className="description">
                            I contenuti veicolati mediante strumenti di comunicazione elettronica da Fater S.p.a.
                            (sia relativi al sito istituzionale, sia a quelli dei singoli prodotti) hanno valore meramente
                            indicativo e non costituiscono impegno a rendere effettivamente disponibili sul mercato i prodotti in questione.
                            Fater S.p.a. si riserva il diritto di modificare a propria discrezione i contenuti dei propri siti.
                            Prima di prendere qualsiasi decisione relativa a prodotti/servizi di Fater S.p.a. è opportuno verificare
                            l’effettiva corrispondenza di quanto pubblicato con le caratteristiche dei prodotti effettivamente commercializzati.
                        </p>
                    </div>
                    <div className="information-item">
                        <h1 className="title">Link esterni</h1>
                        <p className="description">
                            Fater S.p.a. non assume alcuna responsabilità per i contenuti raggiungibili tramite link esterni ai propri siti
                            né garantisce che questi contenuti saranno sempre raggiungibili, disponibili o aggiornati.
                            L’eventuale presenza di link a contenuti di terze parti non implica, da parte di Fater S.p.a. alcun accreditamento,
                            partnership, affiliazione o qualsiasi altro rapporto con i titolari dei contenuti linkati.<br /><br />
                        </p>
                    </div>
                    <div className="information-item">
                        <h1 className="title">Marchi e proprietà intellettuale</h1>
                        <p className="description">
                            Tutti i testi, le immagini, le sequenze audiovisive, i codici informatici e qualsiasi altro elmento
                            protetto dalla legge che fanno parte e/o sono utilizzati sui siti di Fater S.p.a. sono proprietà
                            intellettuale esclusiva di Fater S.p.a. Qualsiasi utilizzo dovrà essere espressamente autorizzato
                            per iscritto. I marchi dei prodotti Fater S.p.a. sono di sua esclusiva proprietà e qualsiasi utilizzo
                            dovrà essere espressamente autorizzato per iscritto.
                        </p>
                    </div>
                    <div className="information-item">
                        <h1 className="title">Informativa sul trattamento dei dati personali</h1>
                        {/*<p className="description">*/}
                        {/*    <a href="#">Privacy portal</a>*/}
                        {/*</p>*/}
                    </div>
                    <div className="information-item">
                        <h1 className="title">Termini e condizioni di utilizzo dei servizi di brand</h1>
                        <div className="description">
                            <div className="sub-description">
                                <h2>1 – Assenza di corrispettivo economico per l’utilizzo dei servizi</h2>
                                <p className="description">
                                    Tutti i testi, le immagini, le sequenze audiovisive, i codici informatici e
                                    qualsiasi altro elmento protetto dalla legge che fanno parte e/o sono utilizzati
                                    sui siti di Fater S.p.a. sono proprietà intellettuale esclusiva di Fater S.p.a.
                                    Qualsiasi utilizzo dovrà essere espressamente autorizzato per iscritto. I marchi dei prodotti Fater S.p.a.
                                    sono di sua esclusiva proprietà e qualsiasi utilizzo dovrà essere espressamente autorizzato per iscritto.
                                </p><br />
                                <h2>2 – Disponibilità, durata e cessazione dei servizi</h2>
                                <p className="description">
                                    I servizi offerti da Fater S.p.a. ai consumatori dei suoi prodotti sono disponibili continuativamente
                                    (fermi restando eventuali blocchi dovuti a necessità tecniche o di altro tipo) e a tempo in determinato.
                                    Fater S.p.a., tuttavia, si riserva il diritto insindacabile di ridurne,sospenderne
                                    e/o interromperne definitivamente la disponibilità.
                                </p><br />
                                <h2>3 – Gestione dei contenuti pubblicati tramite i servizi</h2>
                                <p className="description">
                                    I consumatori che, una volta registrati, pubblicano dei contenuti di qualsiasi tipo
                                    tramite i servizi di Fater S.p.a garantiscono di avere il diritto di disporne e in particolare
                                    di poterli rendere disponibili tramite l’internet. I contenuti rimangono nella titolarità
                                    dei consumatori che li hanno pubblicati, ma nello stesso tempo Fater S.p.a. acquisice
                                    il diritto gratuito non esclusivo, non limitato temporalmente e geograficamente di riutilizzarli
                                    in qualsiasi forma (integrale, sintetica), su supporti cartacei, digitali e telematici,
                                    veicolandoli tramite mezzi di comunicazione anche elettronici, su piattaforme digitali,
                                    satellitarie di telecomunicazioni, per finalità commerciali, di marketing e di pubblicità.
                                    Ferme restando tutte le azioni a tutela dei propri beni aziendali e pur non esercitando
                                    un controllo preventivo, Fater S.p.a. si riserva di rimuovere selettivamente,
                                    a seguito di segnalazione, quei contenuti che fossero palesemente illegali o comunque inopportuni.
                                </p><br />
                                <h2>4 – Buona fede e correttezza nell’utilizzo dei servizi</h2>
                                <p className="description">
                                    La persona che si registra sulle piattaforme dei servizi (a titolo meramente esemplificativo: forum, social network, club, e-commerce e quant’altro) assume l’obbligo di utilizzarli secondo buona fede e correttezza. Quanto sopra implica, tra l’altro ma non esclusivamente: <br/><br />
                                    <ul>
                                        <li>fornire, in fase di registrazione, informazioni veritiere e aggiornarle quando fosse necessario,</li>
                                        <li>essere consapevoli delle informazioni e dei dati che si decide di pubblicare e del fatto che, una volta pubblicati, questi possono essere acquisiti da qualsiasi altro partecipante registrato sulla medesima piattaforma,</li>
                                        <li>evitare comportamenti offensivi o comunque inappropriati nei confronti di altri partecipanti,</li>
                                        <li>verificare di avere acquisito il consenso dell’interessato prima di pubblicare dati, immagini, video e - in generale - informazioni relative ad altre persone,</li>
                                        <li>evitare qualsiasi utilizzo dei servizi per finalità commerciali o che implichino, anche indirettamente, un vantaggio economico, patrimoniale o non patrimoniale per sé o per altri.</li>
                                    </ul>         
                                </p><br />
                                <h2>5 – Trattamento dei dati personali</h2>
                                <p className="description">
                                    Fater S.p.a. rispetta la privacy e tratta i dati personali che le vengono comunicati 
                                    dai consumatori nei limiti dell’informativa disponibile all’indirizzo <a href="#">privacyportal.fatergroup.com</a>.
                                </p>
                                

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bg">
                <div className="container">
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default NoteLegali;