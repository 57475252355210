const Errors = {
    'emailError': {
        title: 'Email non valida',
    },
    'pswError': {
        title: 'Utente non trovato o credenziali errate',
    },
    'sendEmailError': {
        title: 'send email non valida',
    },
};

export default Errors;
