import API from '../api/Api';
import {sNewPreferences} from '../reducers/selectors';
import {
    ACCEPT_BRAND,
    ACCEPT_TERMS,
    DELETE_ACCOUNT,
    EMAIL_VALUE,
    GET_PREFERENCES,
    INIT,
    LOADING_UPDATE,
    LOGOUT,
    PASSWORD_VALUE,
    PREFERENCES_MODIFIED,
    SEND_LINK,
    SEND_LINK_EMAIL,
    SET_ACTIVE_BRAND,
    SET_NEW_PREFERENCE,
    TOGGLE_ACCEPT_TERMS_MODAL, TOGGLE_NOT_EDITABLE_TERMS_MODAL,
    USER_DATA_SET,
} from './ActionType';

export function init() {
    return function(dispatch) {
        dispatch({
            type: INIT,
            // payload: API.index(),
        });
    };
}

export function setEmailValue(val) {
    return {
        type: EMAIL_VALUE,
        payload: val,
    };
}

export function setPasswordValue(val) {
    return {
        type: PASSWORD_VALUE,
        payload: val,
    };
}

export function setSendLinkEmail(val) {
    return {
        type: SEND_LINK_EMAIL,
        payload: val,
    };
}

export function otpAuth(otp) {
    return async function(dispatch, getState) {
        let result;
        try {
            result = await API.otpAuth(otp);
        } catch (e) {
            throw e;
        }
        return result;
        // checkAuthentication();
    };
}

export function sendLink(email) {
    return function(dispatch) {
        dispatch({
            type: SEND_LINK,
            payload: API.sendLink(email),
        });
    };
}

export function userLogout() {
    return {
        type: LOGOUT,
    };
}

export function deleteAccount(brands) {
    return {
        type: DELETE_ACCOUNT,
        payload: API.deleteAccount(brands),
    };
}

export function acceptTerms(bool) {
    return {
        type: ACCEPT_TERMS,
        payload: bool,
    };
}

export function acceptBrand(brand) {
    return {
        type: ACCEPT_BRAND,
        payload: brand,
    };
}

export function getPreferences() {
    return {
        type: GET_PREFERENCES,
        payload: API.getPreferences(),
    };
}

export function setUserData(user) {
    return {
        type: USER_DATA_SET,
        payload: user,
    };
}

export function setActiveBrand(brand) {
    return {
        type: SET_ACTIVE_BRAND,
        payload: brand,
    };
}

export function toggleAcceptTermsModal() {
    return {
        type: TOGGLE_ACCEPT_TERMS_MODAL,
    };
}

export function toggleNotEditableTermsModal() {
    return {
        type: TOGGLE_NOT_EDITABLE_TERMS_MODAL,
    };
}

export function setNewPreference(consentKey, val) {
    return {
        type: SET_NEW_PREFERENCE,
        payload: {
            consentKey, val,
        },
    };
}

export function preferencesModified(bool) {
    return {
        type: PREFERENCES_MODIFIED,
        payload: bool,
    };
}

export function loadingUpdate(bool) {
    return {
        type: LOADING_UPDATE,
        payload: bool,
    };
}

export function sendNewPreferences(checkAuthentication) {
    return function(dispatch, getState) {
        const updatedPref = sNewPreferences(getState());
        // if (typeof updatedPref['pampers|1|privacy_marketing'] !== 'undefined') {
        //     updatedPref['pampers|1|privacy_marketing_email'] = updatedPref['pampers|1|privacy_marketing'];
        //     updatedPref['pampers|1|privacy_marketing_posta'] = updatedPref['pampers|1|privacy_marketing'];
        //     updatedPref['pampers|1|privacy_marketing_telefono'] = updatedPref['pampers|1|privacy_marketing'];
        //     delete updatedPref['pampers|1|privacy_marketing'];
        // }
        // if (typeof updatedPref['lines|1|privacy_marketing'] !== 'undefined') {
        //     updatedPref['lines|1|privacy_marketing_email'] = updatedPref['lines|1|privacy_marketing'];
        //     updatedPref['lines|1|privacy_marketing_posta'] = updatedPref['lines|1|privacy_marketing'];
        //     updatedPref['lines|1|privacy_marketing_telefono'] = updatedPref['lines|1|privacy_marketing'];
        //     delete updatedPref['lines|1|privacy_marketing'];
        // }
        const keys = Object.keys(updatedPref);
        if (!keys.length) {
            dispatch(loadingUpdate(false));
            return;
        }
        let newPref = {};

        keys.forEach(function(pref) {
            const [b, ks, kc] = pref.split('|');
            if (typeof newPref[b] == 'undefined') {
                newPref[b] = {
                    [ks]: {
                        [kc]: updatedPref[pref] === 'on' ?
                            'y' :
                            'n',
                    },
                };
            } else {
                if (typeof newPref[b][ks] == 'undefined') {
                    newPref[b] = {
                        ...newPref[b],
                        [ks]: {[kc]: updatedPref[pref] === 'on' ? 'y' : 'n'},
                    };
                } else {
                    newPref[b] = {
                        ...newPref[b],
                        [ks]: {
                            ...newPref[b][ks],
                            [kc]: updatedPref[pref] === 'on' ? 'y' : 'n',
                        },
                    };
                }
            }
        });
        API.updatePreferences(newPref).then(function() {
            API.myData().then(function(data) {
                dispatch(preferencesModified(true));
                dispatch(loadingUpdate(false));
                dispatch(setUserData(data));
            });

        })
            .catch(function() {
                API.myData().then(function(data) {
                    dispatch(preferencesModified(false));
                    dispatch(loadingUpdate(false));
                    dispatch(setUserData(data));
                });
            });

        checkAuthentication();
    };
}
