import {combineReducers} from 'redux';
import AppReducer from './AppReducer';
import PrivacyReducer from './PrivacyReducer';
import UserReducer from './UserReducer';

const rootReducer = combineReducers({
    app: AppReducer,
    privacy: PrivacyReducer,
    user:UserReducer,
});

export default rootReducer;
