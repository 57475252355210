import classnames from 'classnames';
import React from 'react';

function InformativaBox({
                            title, color, cta = 'Leggi tutto',
                            text = 'Clicca per leggere l\'informativa privacy completa.',
                            classCss,
                            link
                        }) {
    return (
        <div className="informativa-box">
            <h2 style={{marginBottom: 8}}>{title}</h2>
            <p style={{marginBottom: 24}}>{text}</p>
            <a className={classnames('brand-button', {
                [classCss]: true
            })} href={link}>{cta}</a>
        </div>
    );
}

export default InformativaBox;
