import {useCallback, useEffect, useState} from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {useDispatch, useSelector} from 'react-redux';
import AcceptTermsModal from './components/AcceptTermsModal';
import Footer from './components/Footer';
import Header from './components/Header';
import DeleteAlert from './components/manage-delete/DeleteAlert';
import DeleteManagement from './components/manage-delete/DeleteManagement';
import PrivacyManagement from './components/manage-privacy/PrivacyManagement';
import NotEditableContentModal from './components/NotEditableContentModal';
import SaveAlert from './components/SaveAlert';
import UserAccounts from './components/UserAccounts';
import {sPreferencesModified} from './reducers/selectors';
import {getPreferences} from './stores/AppAction';

function GestionePrivacy() {
    const dispatch= useDispatch();
    const [showDelete, setShowDelete] = useState(false)
    const preferencesModified = useSelector(sPreferencesModified);

    const handleShowDelete = useCallback(() => {
        setShowDelete(!showDelete);
    }, [showDelete])

    useEffect(() => {
        dispatch(getPreferences());
    },[])

    return (
        <div className="gest-privacy">
            {/*<PrivacyAlert id="privacy-alert" />*/}
            <AcceptTermsModal />
            <NotEditableContentModal />
            <SaveAlert show={preferencesModified} id="save-alert"/>
            <DeleteAlert show={showDelete} setshow={handleShowDelete} id="delete-alert"/>
            <div id="overlay-fp" className="overlay-fullpage"></div>
            <Header/>
            <div id="overlay" className="overlay-menu"></div>
            <div className="container-bg">
                <div className="left-bg"></div>
                <div className="right-bg"></div>
                <div className="container">
                    <div className="left-content">
                        <h1>Gestione Privacy</h1>
                        <p>
                            Gentile utente,
                            <br/><br/>da questa pagina hai la possibilità
                            di <span>modificare </span>
                            le tue preferenze privacy oppure richiedere
                            la <span>cancellazione</span> del tuo account.
                        </p>
                        <button className="main-btn mobile-btn">
                            Modifica Preferenze
                        </button>
                        <DeleteManagement handleShowDelete={handleShowDelete}/>
                    </div>
                    <div className="right-content">
                        <UserAccounts/>
                        <PrivacyManagement />
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
    );
}

export default GestionePrivacy;
