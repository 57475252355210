import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';

export default function CreateStore (reducers) {

    const middleware = [thunk, promise];
    const enhancers = [applyMiddleware(...middleware)];
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ||
        compose;

    const store = createStore(reducers,
        composeEnhancers(...enhancers));


    return {store};
}

