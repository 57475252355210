import classNames from 'classnames';
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import Spinner from 'react-activity/dist/Spinner';
import {useDispatch, useSelector} from 'react-redux';
import {useSanctum} from 'react-sanctum';
import {
    sAcceptedTerms, sAccountBrands, sActiveBrand, sBrandsAccepted,
    sCurrentSettings,
    sLoadingPreferences, sLoadingUpdate, sPreferences,
} from '../reducers/selectors';
import {
    loadingUpdate,
    sendNewPreferences,
    toggleAcceptTermsModal, toggleNotEditableTermsModal,
} from '../stores/AppAction';
import Consent from './manage-privacy/Consent';
import SwitchButton from './SwitchButton';

function PrivacySettings({}) {
    const {checkAuthentication} = useSanctum();
    const dispatch = useDispatch();
    const preferences = useSelector(sPreferences);
    const accepted = useSelector(sAcceptedTerms);
    const loadingP = useSelector(sLoadingPreferences);
    const currentSetting = useSelector(sCurrentSettings);
    const acceptedTerms = useSelector(sAcceptedTerms);
    const activeBrand = useSelector(sActiveBrand);
    const brands = useSelector(sAccountBrands);
    const brandsAccepted = useSelector(sBrandsAccepted);
    const loading = useSelector(sLoadingUpdate);

    const showSend = useCallback(function() {
        switch (activeBrand) {
            case 'tampax':
                return brandsAccepted.includes('tampax');
            case 'pampers':
            case 'hero':
            case 'partners':
            case 'mfp':
            case 'maternity':
                return brandsAccepted.includes('pampers');
            case 'lines':
                if (brands.includes('lines')) {
                    return brandsAccepted.includes('lines');
                } else {
                    return brandsAccepted.includes('pampers');
                }
            case 'ace':
                if (brands.includes('ace')) {
                    return brandsAccepted.includes('ace');
                } else {
                    return brandsAccepted.includes('pampers');
                }
            case 'specialist':
                return brandsAccepted.includes('specialist')
            case 'partnersLines':
                return brandsAccepted.includes('lines');
        }
    }, [activeBrand, brandsAccepted]);

    const handleShowAcceptTerms = useCallback((e) => {
        if (typeof e !== 'undefined') {
            e.preventDefault();
        }
        if (showSend()) {
            dispatch(loadingUpdate(true))
            dispatch(sendNewPreferences(checkAuthentication));
        } else {
            dispatch(toggleAcceptTermsModal());
        }
    }, [dispatch, showSend]);

    const handleShowNotEditableConsent = useCallback((e) => {
        dispatch(toggleNotEditableTermsModal());
    }, [dispatch]);

    if (loadingP) {
        return (
            <div className={'spin-brands'}>
                <Spinner size={1} animating={true}
                         speed={0.5}
                         color={'rgb(10, 20, 30)'}
                         style={{height: '100px', width: '100px'}}/>
            </div>
        );
    }

    if (!currentSetting || !preferences || !brandsAccepted) return null;

    return (
        <form className="settings" onSubmit={handleShowAcceptTerms}>
            {/*{loadingP && <div className={'spin-brands'}>*/}
            {/*    <Spinner size={1} animating={true}*/}
            {/*             speed={0.5}*/}
            {/*             style={{height: '100px', width: '100px'}}/>*/}
            {/*</div>}*/}
            {/*{(!loadingP && activeBrand) &&*/}
            {/*    Object.keys(activeBrand).map((preference,i) => {*/}
            {/*        const keys = Object.keys(preference);*/}
            {/*        return (*/}
            {/*            <Fragment>*/}
            {/*                <div className="setting-item">*/}
            {/*                    <p>{activeBrand[preference]}</p>*/}
            {/*                    <SwitchButton acceptedTerms={acceptedTerms} value={preference[keys[1]]} id={`btn-${i}`} nameForm={`setting-${i}`} />*/}
            {/*                </div>*/}
            {/*                <hr />*/}
            {/*            </Fragment>*/}
            {/*        )*/}
            {/*    })*/}
            {/*}*/}
            {Object.keys(currentSetting).map(function(key) {
                const [b, ks, kc] = key.split('|');
                // console.log(preferences, preferences[b], b, kc, key);
                // console.log(currentSetting, key, currentSetting[key]);
                return <Consent
                    acceptedTerms={showSend()}
                    key={key}
                    consentKey={key}
                    text={preferences[b][kc] ? preferences[b][kc] : `${b}_${kc}`}
                    consent={currentSetting[key]}
                    disabledCallback={handleShowAcceptTerms}
                    notEditableCallback={handleShowNotEditableConsent}
                />;
            })}
            <button  type="submit"
                    className={classNames('main-btn', {disabled: showSend()})}>
                Modifica
            </button>
            {loading && (
                <div className={'spin main-btn'}>
                    <Spinner size={1} className={'rai-spinner white'} color="#ffffff" animating speed={0.5}
                             style={{height: '30px', width: '30px'}} />
                </div>
            )}
            {!loading && (
                <button  type="submit"
                    className={classNames('main-btn green', {active: showSend(), disabled: !showSend})}>
                    Salva
                </button>
            )}

        </form>

    );
}

export default PrivacySettings;
