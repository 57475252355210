import React, {Fragment, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Brand, Informative} from '../constants/Constants';
import iconClose from '../img/icon-close.svg';
import {
    sAccountBrands, sActiveBrand, sBrandsAccepted,
    sPreferences,
    sShowModalAcceptedTerms,
} from '../reducers/selectors';
import {
    acceptBrand,
    acceptTerms,
    getPreferences,
    toggleAcceptTermsModal,
} from '../stores/AppAction';

export default function AcceptTermsModal() {
    const dispatch = useDispatch();
    const show = useSelector(sShowModalAcceptedTerms);
    const brands = useSelector(sAccountBrands);
    const brandsAccepted = useSelector(sBrandsAccepted);
    const activeBrand = useSelector(sActiveBrand);

    const showTerms = useCallback(function() {
        switch (activeBrand) {
            case 'tampax':
                return ['tampax'];
            case 'pampers':
            case 'mfp':
            case 'partners':
            case 'hero':
            case 'maternity':
                return ['pampers'];
            case 'lines':
                if (brands.includes('lines')) {
                    return ['lines'];
                } else {
                    return ['pampers'];
                }
            case 'ace':
                if (brands.includes('ace')) {
                    return ['ace'];
                } else {
                    return ['pampers'];
                }
            case 'specialist':
                return ['specialist'];
            case 'partnersLines':
                return ['lines'];
        }
    }, [activeBrand, brandsAccepted]);

    const handleAcceptTerms = useCallback(() => {
        dispatch(toggleAcceptTermsModal());
        dispatch(acceptTerms(true));
        const brand = showTerms();
        dispatch(acceptBrand(brand));
    }, [dispatch, acceptTerms, showTerms]);

    const handleShowAcceptTerms = useCallback(() => {
        dispatch(toggleAcceptTermsModal());
    }, [dispatch, toggleAcceptTermsModal]);

    if (!show) return null;
    return (
        <Fragment>
            <div className="container alert">
                <div className="alert-container terms">
                    <img src={iconClose} onClick={handleShowAcceptTerms}
                         alt="icona chiudi" className="icon-close" />
                    <h3>Presa visione informativa</h3>
                    <p>
                        Prima di procedere con l'aggiornamento dei consensi confermo di aver preso visione dell'{
                        showTerms().map((br,i) => {
                            return (
                                <Fragment key={br}>
                                    <a href={Informative[br]}>informativa {Brand[br]}</a>
                                </Fragment>
                            )
                        })
                    } sul trattamento dei
                        dati personali come da Regolamento UE 2016/679.
                    </p>
                    {showTerms().find(br => br === 'lines') &&
                        <p>
                            Prima di procedere con l'aggiornamento dei consensi che ho prestato sull' app My Lines
                            confermo di aver preso visione dell'<a href={Informative[`lines-app`]}>informativa dell'app My Lines</a> sul trattamento dei dati personali come da Regolamento UE 2016/679
                        </p>
                    }
                    <button onClick={handleAcceptTerms} className="main-btn">
                        Confermo
                    </button>
                </div>
            </div>
            <div className={'alert-bg'} />
        </Fragment>
    );
}
