import classNames from 'classnames';
import React, {useCallback, useState} from 'react';
import Spinner from 'react-activity/dist/Spinner';
import {useDispatch, useSelector} from 'react-redux';
import Errors from '../../constants/Errors';
import iconAlert from '../../img/icon-alert.svg';
import {
    sLoadingLink,
    sSendError,
    sSendLinkEmail, sUserNotFound,
} from '../../reducers/selectors';
import {sendLink} from '../../stores/AppAction';

export default function LoginWithEmailLink({loadingLink, email, handleSendLinkEmail, handleSendLink}) {

    const sendError = useSelector(sSendError);

    return (
        <form onSubmit={handleSendLink} action="#"
              id="gestione-privacy-email"
              className="form">
            <input className={classNames({
                'error-input-active': sendError ===
                    Errors.sendEmailError.title,
            })} autoComplete={'off'} onChange={handleSendLinkEmail}
                   type="email" value={email} name="email"
                   placeholder="e-mail" required />
            <span className={classNames('error-txt', {
                'error-txt-active': sendError ===
                    Errors.sendEmailError.title,
            })}> <img
                src={iconAlert} className="icon-alert"
                alt="icona allerta" /> e-mail non valida</span>
            {sendError === 404 &&
                <span className={'error-txt error-txt-active'}>
                    <img src={iconAlert} className="icon-alert"
                    alt="icona allerta" /> Utente non trovato </span>
            }
            {loadingLink && (
                <div className={'spin'}>
                    <Spinner size={1} className={'rai-spinner white'} color="#ffffff" animating speed={0.5}
                             style={{height: '30px', width: '30px'}} />
                </div>
            )}
            {
                !loadingLink && <input type="submit" value="Invia Link"
                                       className="main-btn" />
            }
        </form>
    );
}
