import React, {Fragment} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useSelector} from 'react-redux';
import {sConsentBrands} from '../../reducers/selectors';
import BrandTab from './BrandTab';

export default function BrandsTabBar () {
    const brands = useSelector(sConsentBrands);
    return (
        <Fragment>
            <div className={'desk-tabbar'}>
                {brands.map((brand) => <BrandTab brand={brand} key={brand}/>)}
            </div>
            <div className={'mobile-tabbar'}>
                <PerfectScrollbar
                    // component={'div'}
                    className={'brands-section'}
                    option={{
                        handlers: [
                            'click-rail',
                            'drag-thumb',
                            'keyboard',
                            'wheel',
                            'touch'
                        ],
                        maxScrollbarLength: 300,
                        swipeEasing: true,
                    }}
                >
                    {/*<div className={'brands-section'}>*/}
                    {brands.map((brand) => <BrandTab brand={brand} key={brand}/>)}
                    {/*</div>*/}
                </PerfectScrollbar>
            </div>
        </Fragment>
    );
}
