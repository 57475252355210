import {loadingUpdate} from '../stores/AppAction';

export function sApp(state) {
    return state.app;
}
export function sPrivacy(state) {
    return state.privacy;
}
export function sUser(state) {
    return state.user;
}

export function sEmailValue(state) {
    return sApp(state).email
}

export function sPasswordValue(state) {
    return sApp(state).password
}

export function sLoadingLogin(state) {
    return sApp(state).loadingLogin
}

export function sUserLogged(state) {
    return sApp(state).userLogged
}

export function sLoadingLink(state) {
    return sApp(state).loadingLink
}

export function sLinkSended(state) {
    return sApp(state).linkSended
}

export function sDeletedAccount(state) {
    return sApp(state).deleteAccount;
}

export function sLoadingDeletedAccount(state) {
    return sApp(state).loadingDeleteAccount;
}

export function sLoginError(state) {
    return sApp(state).loginError
}

export function sSendError(state) {
    return sApp(state).sendError
}

export function sSendLinkEmail(state) {
    return sApp(state).sendLinkEmail
}

export function sShowModalAcceptedTerms(state) {
    return sApp(state).showModalAcceptedTerms;
}

export function sShowModalNotEditableConsent(state) {
    return sApp(state).showModalNotEditableConsent;
}

export function sAcceptedTerms(state) {
    return sApp(state).acceptedTerms
}

export function sPreferences(state) {
    return sApp(state).preferences
}

export function sNewPreferences(state) {
    return sPrivacy(state).updatedConsent;
}

export function sLoadingPreferences(state) {
    return sApp(state).loadingPreferences
}

export function sConsentBrands(state) {
    return sPrivacy(state).brands;
}
export function sAccountBrands(state) {
    return sPrivacy(state).accountBrands;
}
export function sActiveBrand(state) {
    return sApp(state).activeBrand;
}
export function sUserCurrent(state) {
    return sUser(state).user;
}
export function sLoadingUpdate(state) {
    return sApp(state).loadingUpdate;
}
export function sBrandsAccepted(state) {
    return sApp(state).brandsAccepted;
}
export function sPreferencesModified(state) {
    return sApp(state).preferencesModified;
}

export function sCurrentSettings(state) {
    const brand = sActiveBrand(state);
    return sPrivacy(state).consents[brand];
}
