import axios from 'axios';

const instance = axios.create({
    // baseURL: '/demo/',
    baseURL: '',
    withCredentials: true,
});

export const  sanctumConfig = {
    apiUrl: "https://privacyportal.fatergroup.com",
    csrfCookieRoute: 'sanctum/csrf-cookie',
    signInRoute: 'login-portal',
    signOutRoute: 'logout',
    userObjectRoute: 'my-data',
};

// export const sanctumConfig = {
//     apiUrl: 'http://localhost:3000',
// //     apiUrl: 'http://192.168.178.65:3000',
//     csrfCookieRoute: 'demo/sanctum/csrf-cookie',
//     signInRoute: 'demo/login-portal',
//     signOutRoute: 'demo/logout',
//     userObjectRoute: 'demo/my-data',
// };

export default class Api {

    static sendLink(email) {
        return instance.post('login-email/send-email', {
            email: email,
        })
            .then(data => {
                return data.data;
            })
            .catch((error) => {
                throw {status: error.response.status};
            });
    }

    static otpAuth(otp) {
        return instance.post('login-email/attempt-login', {
            otp: otp,
        }).then(response => {
            return response.data;
        }).catch((error) => {
            throw {status: 404};
        });
    }

    static deleteAccount(brandsToDelete = []) {
        return instance.post('cancellation-request', {
            brands: brandsToDelete,
        });
        return new Promise(function(resolve, reject) {
            setTimeout(function() {
                resolve(true);
                // reject({
                //     error: {
                //         response: {
                //             status: 401,
                //             data: {
                //                 errors: {
                //                     sendEmail:['not logged'],
                //                 },
                //                 message: '',
                //             },
                //         },
                //     },
                // });
            }, 2000);
        });
    }

    static updatePreferences(data) {
        return instance.post('update-contents', {
            consents: data,
        }).then(data => {
            return data.data;
        });
    }

    static myData(data) {
        return instance.get('my-data').then(data => {
            return data.data;
        });
    }

    static getPreferences() {
        return instance.get('privacy_labels.json').then(data => {
            return data.data['it.privacy_labels'];
        });
    }

}
