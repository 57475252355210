import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useSanctum} from 'react-sanctum';
import logo from '../img/logo-privacy-portal.svg';
import {sUserLogged} from '../reducers/selectors';
import {userLogout} from '../stores/AppAction';

function Header() {
    const userLogged = useSelector(sUserLogged);
    const dispatch = useDispatch();
    const history = useHistory();
    const {authenticated, signOut} = useSanctum();

    const logout = useCallback(() => {
        dispatch(userLogout());
        signOut();
    }, []);

    const handleNavigate = useCallback((e) => {
        e.preventDefault();
        switch (e.target.id) {
            case 'home':
                history.replace('');
                break;
            case 'info':
                history.replace('informativa');
                break;
            case 'note':
                history.replace('note-legali');
                break;
            case 'privacy':
                history.replace('');
                break;
            default:
                history.replace('/');
                break;
        }
        // menu();
        const overlay = document.getElementById('overlay');
        const links = document.getElementById('links');
        const hamb = document.getElementById('hamb');
        overlay.style.display = 'none';
        links.classList.remove('active');
        hamb.classList.remove('active');
        document.body.style.overflow = 'auto';
    }, []);

    function menu(e) {
        e && e.preventDefault();
        const overlay = document.getElementById('overlay');
        const links = document.getElementById('links');
        const hamb = document.getElementById('hamb');
        if (links.classList.contains('active')) {
            overlay.style.display = 'none';
            links.classList.remove('active');
            hamb.classList.remove('active');
            document.body.style.overflow = 'auto';
        } else {
            overlay.style.display = 'block';
            links.classList.toggle('active');
            hamb.classList.toggle('active');
            document.body.style.overflow = 'hidden';
        }
    }

    return (
        <header className="header">
            <a href="/" onClick={handleNavigate}><img src={logo} className="header-logo"
                             alt="logo fater" /></a>

            <div className="menu">
                <div id="links" className="links">
                    {!authenticated &&
                    <div className="menu-link">
                        <a id={'home'} href="#"
                           onClick={handleNavigate}> Home </a>
                    </div>
                    }
                    <div className="menu-link">
                        <a id={'info'} href="/infomativa"
                           onClick={handleNavigate}> Informativa Privacy </a>
                    </div>
                    <div className="menu-link">
                        <a id={'note'} href="#" onClick={handleNavigate}> Note
                            Legali </a>
                    </div>
                    {authenticated &&
                    <div className="menu-link">
                        <a id={'privacy'} href="#"
                           onClick={handleNavigate}> Gestione Privacy </a>
                    </div>
                    }
                </div>

                {
                    authenticated &&
                    <button onClick={logout} className="header-btn">
                        <span>Logout</span></button>
                }

                {
                    !authenticated &&
                    <button id={'home'} onClick={handleNavigate} className="header-btn"><span>Accedi</span></button>
                }

                <button id="hamb" className="hamb-btn" onClick={menu}></button>
            </div>
        </header>
    );
}

export default Header;
