import Header from './components/Header';
import Footer from './components/Footer';
import InformativaBox from './components/informative/InformativaBox';
import InformativaSeparator
    from './components/informative/InformativaSeparator';
import {Informative} from './constants/Constants';

function InformativaPrivacy() {

    return (
        <div className="informations">
            <Header />
            <div id="overlay" className="overlay-menu"></div>
            <div className="top-section">
                <div className="container">
                    <div className="title-text">
                        <h1>INFORMATIVA PRIVACY</h1>
                        <p>
                            Di seguito sono presenti le Informative Privacy relative a tutti i Brand Fater.
                        </p>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="content">
                    <div className={'informative-box-container'}>
                        <InformativaBox link={Informative.fornitori} classCss={'tampax'} title={'INFORMATIVA PRIVACY CONTRATTI'}/>
                        <InformativaBox link={Informative.statement} classCss={'tamoax'} title={'CONTRACTS PRIVACY STATEMENT'} text={'Click here to read the full privacy statement.'} cta={'Read more'}/>
                    </div>
                    <InformativaSeparator />
                    <div className={'informative-box-container'}>
                        <InformativaBox link={Informative.pampers} classCss={'pampers'} title={'CLUB COCCOLE PAMPERS'}/>
                    </div>
                    <InformativaSeparator />
                    <div className={'informative-box-container'}>
                        <InformativaBox link={Informative.lines} classCss={'lines'} title={'LINES'}/>
                        <InformativaBox link={Informative.ace} colclassCssor={'ace'} title={'ACE'}/>
                    </div>
                    <InformativaSeparator />
                    <div className={'informative-box-container'}>
                        <InformativaBox link={Informative.specialist} classCss={'specialist'} title={'LINES SPECIALIST'}/>
                        <InformativaBox link={Informative.tampax} classCss={'tampax'} title={'TAMPAX'}/>
                    </div>
                    <InformativaSeparator />
                    <div className={'informative-box-container'}>
                        <InformativaBox link={Informative.customerCare} classCss={'serv'} title={'SERVIZIO CONSUMATORI'}/>
                    </div>
                    <InformativaSeparator />
                    <div className={'informative-box-container'}>
                        <InformativaBox link={Informative.angeliniConsumer} classCss={'serv'} title={'PAMPERS ex. art. 14 (iMamma)'}/>
                    </div>
                </div>
            </div>
            <div className="footer-bg">
                <div className="container">
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default InformativaPrivacy;
