import {USER_DATA_SET} from '../stores/ActionType';

const INITIAL_STATE = {
    user: null
};

export default function UserReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case USER_DATA_SET:
            return {
                ...state,
                user: action.payload.user,
            }
        default:
            return state;
    }
}
