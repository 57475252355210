import React, {Fragment, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {useSanctum} from 'react-sanctum';
import GestionePrivacy from '../GestionePrivacy';
import Home from '../Home';
import InformativaPrivacy from '../InformativaPrivacy';
import NoteLegali from '../NoteLegali';
import {setUserData} from '../stores/AppAction';

export default function PrivacyPortal() {
    const {authenticated, user, checkAuthentication} = useSanctum();
    const dispatch = useDispatch();

    useEffect(function() {
        if (user) {
            dispatch(setUserData(user));
        }
    }, [user, dispatch])

    return (
        <Fragment>
            {authenticated &&
            <Router>
                <Switch>
                    <Route path="/informativa" exact>
                        <InformativaPrivacy />
                    </Route>
                    <Route path="/note-legali" exact>
                        <NoteLegali />
                    </Route>
                    <Route path="/" exact>
                        <GestionePrivacy />
                    </Route>
                </Switch>
            </Router>
            }
            {authenticated===false &&
            <Router>
                <Switch>
                    <Route path="/informativa" exact>
                        <InformativaPrivacy />
                    </Route>
                    <Route path="/note-legali" exact>
                        <NoteLegali />
                    </Route>
                    <Route path="/?otp">
                        <Home />
                    </Route>
                    <Route path="/" exact>
                        <Home />
                    </Route>
                </Switch>
            </Router>
            }
        </Fragment>
    );
}
