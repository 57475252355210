import classNames from 'classnames';
import {useCallback, useRef, useState} from 'react';

function SwitchButton({value, id, disabled, nameForm, callback, disabledCallback}) {
    const [val, setVal] = useState(value === 'on' ? 'on' : 'off');

    const handleOn = useCallback((e) => {
        if (!disabled) {
            e.stopPropagation();
            setVal('on');
            callback('on');
        } else {
            disabledCallback && disabledCallback();
        }
    }, [val, disabled, disabledCallback]);

    const handleOff = useCallback((e) => {
        if (!disabled) {
            e.stopPropagation();
            setVal('off');
            callback('off');
        } else {
            disabledCallback && disabledCallback();
        }
    }, [val, disabled,disabledCallback]);

    const handleToggle = useCallback((e) => {
        if (!disabled) {
            e.stopPropagation();
            setVal(val === 'on' ? 'off' : 'on');
            callback(val === 'on' ? 'off' : 'on');
        } else {
            disabledCallback && disabledCallback();
        }
    }, [val, disabled, disabledCallback]);

    return (
        <div className={'toggle-section'} style={{cursor: 'pointer'}}>
            <label htmlFor={`${id}-off`}> NO </label>
            <div
                id={id}
                className={classNames('toggle-switch', {
                    [val]: true,
                    active: !disabled,
                })}
                onClick={handleToggle}
            >
                <div className="input-cont icon-face-l">
                    <input onClick={handleOff}
                           disabled={disabled}
                           defaultChecked={val !== 'on'} id={`${id}-off`}
                           name={nameForm} type="radio" />
                    <label htmlFor={`${id}-off`}
                           className="radio_label" />
                </div>
                <div className="input-cont unset">
                </div>
                <div className="input-cont icon-face-r">
                    <input onClick={handleOn}
                           disabled={disabled}
                           defaultChecked={val === 'on'}
                           id={`${id}-on`}
                           name={nameForm} type="radio" />
                    <label htmlFor={`${id}-on`} className="radio_label" />
                </div>
                <div className="slider"></div>
            </div>
            <label htmlFor={`${id}-on`}>SI</label>
        </div>
    );
}

export default SwitchButton;
