import React, {Fragment, useCallback, useState} from 'react';
import {Spinner} from 'react-activity';
import 'react-activity/dist/Spinner.css';
import {useDispatch, useSelector} from 'react-redux';
import iconCheck from '../../img/icon-check.svg';
import {sLinkSended, sLoadingLink} from '../../reducers/selectors';
import {sendLink} from '../../stores/AppAction';
import LoginWithCredentials from './LoginWithCredentials';
import LoginWithEmailLink from './LoginWithEmailLink';

export default function LoginForm() {
    const dispatch = useDispatch();
    const linkSended = useSelector(sLinkSended);
    const loadingLink = useSelector(sLoadingLink);
    const [email, setEmail] = useState('');

    const handleSendLinkEmail = useCallback((e) => {
        setEmail(e.target.value);
    }, [setEmail]);

    const handleSendLink = useCallback((e) => {
        e.preventDefault();
        dispatch(sendLink(email));
    }, [dispatch, email]);

    return (
        <Fragment>
            {!linkSended &&
            <div style={{display: 'block'}}
                 className="form-section">
                <h2>Gestione Privacy</h2>
                <p>
                    Per gestire le tue privacy accedi al portale con
                    le stesse credenziali del sito per il quale
                    intendi modificare le preferenze privacy.
                </p>
                <LoginWithCredentials/>

                <div className="divider">
                    <p>oppure</p>
                </div>
                <p>Inserisci di seguito l'e-mail con cui ti sei registrato al
                    Club Coccole Pampers o al sito di uno dei marchi di Fater SpA per
                    i quali desideri modificare le preferenze privacy. Ti verrà
                    inviato un link sulla tua email per accedere al portale.</p>
                <LoginWithEmailLink loadingLink={loadingLink} email={email}
                                    handleSendLink={handleSendLink}
                                    handleSendLinkEmail={handleSendLinkEmail}/>
            </div>
            }

            {linkSended &&
            <form style={{display: 'flex'}}
                  onSubmit={handleSendLink}
                  className="confirmation">
                <img src={iconCheck} className="icon-check"/>
                <h2>E-mail inviata correttamente</h2>
                <p>Controlla la tua casella di posta e clicca sul
                    bottone presenta
                    all’interno <span>&nbsp;&nbsp;&nbsp;&nbsp;</span> dell’e-mail
                    per eseguire l’accesso.</p>
                <hr></hr>
                <p>Se non hai ricevuto l’e-mail?</p>
                {
                    loadingLink &&
                    <div className={'spin'}>
                        <Spinner size={1} className={'rai-spinner white'}
                                 color="#ffffff" animating speed={0.5}
                                 style={{height: '30px', width: '30px'}}/>
                    </div>
                }
                {
                    !loadingLink &&
                    <input value="Invia Link"
                           type={'submit'}
                           className="main-btn"/>
                }
            </form>
            }
        </Fragment>
    );
}
