import logo from '../img/logo-privacy-portal.svg';

function Footer() {
    return (
        <div className="footer">
            <img src={logo} className="logo-footer" alt="fater logo"/>
            <p>© 2021 Fater SpA - P.Iva IT 01282360682</p>
        </div>
    );
}

export default Footer;