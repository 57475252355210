import React from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import LoginForm from './components/home/LoginForm';

function Home() {
    return (
        <div className="home">
            <Header/>
            <div id="overlay" className="overlay-menu"></div>
            <div className="container-bg">
                <div className="left-bg"></div>
                <div className="right-bg"></div>
                <div className="container">
                    <div className="left-content">
                        <div className="out-grid-text">
                            <h1>Benvenuto nel portale dedicato alle privacy di
                                Fater SpA</h1>
                            <p className="welcome-text">Da questo portale puoi
                                verificare e modificare le tue preferenze
                                privacy relative a tutti i marchi di Fater SpA o
                                richiedere la cancellazione dei tuoi
                                account. </p>
                            <p className="welcome-text">Accedi con le tue
                                credenziali e se non ricordi la password puoi
                                recuperarla dal sito dal quale hai
                                effettuato la registrazione.</p>
                        </div>
                    </div>
                    <div className="right-content">
                        <LoginForm/>
                    </div>
                </div>
                <Footer/>
            </div>
        </div>
    );
}

export default Home;
