import React, {Fragment, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import iconClose from '../img/icon-close.svg';
import check from '../img/icon-check.svg';
import {preferencesModified} from '../stores/AppAction';


function SaveAlert({show}) {
    const dispatch = useDispatch();

    const handleShowModal = useCallback(function() {
        dispatch(preferencesModified(false));
    },[])

    if (!show) return null;
    return (
        <Fragment>
            <div className="container alert">
                <div className="alert-container alert terms">
                    <img src={iconClose} alt="icona chiudi" onClick={handleShowModal} className="icon-close"/>
                    <img src={check} className="icon-check" alt="icona check" />
                    <h3>Le modifiche apportate sono state salvate con successo,</h3>
                    <p>a breve riceverai un’e-mail di conferma.</p>
                    <button onClick={handleShowModal} className="main-btn">Torna alla gestione</button>
                </div>
            </div>
            <div className={'alert-bg'} />
        </Fragment>
    );
}

export default SaveAlert;