export const INIT = 'INIT';
export const EMAIL_VALUE = 'EMAIL_VALUE';
export const PASSWORD_VALUE = 'PASSWORD_VALUE';
export const LOGIN = 'LOGIN';
export const SEND_LINK = 'SEND_LINK';
export const SEND_LINK_EMAIL = 'SEND_LINK_EMAIL';
export const CHECK_USER_AUTH = 'CHECK_USER_AUTH';
export const LOGOUT = 'LOGOUT';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const ACCEPT_TERMS = 'ACCEPT_TERMS';
export const GET_PREFERENCES = 'GET_PREFERENCES';

export const USER_DATA_SET = 'USER_DATA_SET';
export const SET_ACTIVE_BRAND = 'SET_ACTIVE_BRAND';
export const TOGGLE_ACCEPT_TERMS_MODAL = 'TOGGLE_ACCEPT_TERMS_MODAL';
export const TOGGLE_NOT_EDITABLE_TERMS_MODAL = 'TOGGLE_NOT_EDITABLE_TERMS_MODAL';
export const SET_NEW_PREFERENCE = 'SET_NEW_PREFERENCE';
export const SEND_NEW_PREFERENCES = 'SEND_NEW_PREFERENCES';
export const ACCEPT_BRAND = 'ACCEPT_BRAND';
export const PREFERENCES_MODIFIED = 'PREFERENCES_MODIFIED';
export const LOADING_UPDATE = 'LOADING_UPDATE';
export const OTP_AUTH = 'OTP_AUTH';
