import classNames from 'classnames';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Brand} from '../../constants/Constants';
import {sActiveBrand} from '../../reducers/selectors';
import {setActiveBrand} from '../../stores/AppAction';

export default function BrandTab({brand}) {
    const dispatch = useDispatch();
    const activeBrand = useSelector(sActiveBrand);
    return (
        <button onClick={() => {
            dispatch(setActiveBrand(brand));
        }} className={classNames('brand-cta brand', {
            active: brand === activeBrand,
        })}>
            {Brand[brand]}
        </button>
    );
}
