export const Brand = {
    lines: "Lines",
    pampers: "Pampers",
    ace: "ACE",
    specialist: "Lines Specialist",
    hero: "Hero Solo",
    tampax: "Tampax",
    maternity:"Specialist Maternity",
    partners: "Partner di Pampers",
    partnersLines: "Partner di Lines",
    mfp: "iMamma"
}

export const Informative = {
    lines: "https://authlines.fatergroup.com/informative-privacy",
    'lines-app': "https://lines.it/informativa-privacy/app-my-lines",
    pampers: "https://authpampers.fatergroup.com/informative-privacy",
    ace: "https://authace.fatergroup.com/informative-privacy",
    specialist: "https://authspecialist.fatergroup.com/informative-privacy",
    hero: "https://authpampers.fatergroup.com/informative-privacy",
    tampax: "https://authtampax.fatergroup.com/informative-privacy",
    customerCare: "https://privacyportal.fatergroup.com/pdf/informativa-customer-care.pdf",
    angeliniConsumer: "https://authpampers.fatergroup.com/uploads/Angelini-Consumer-Fater-informativa-ex-art-14-GDPR-revisioni-del-12-7-22.pdf",
    partners: "https://authpampers.fatergroup.com/informative-privacy",
    partners_lines: "https://lines.it/informativa-privacy/app-my-lines",
    mfp: "https://authpampers.fatergroup.com/informative-privacy",
    fornitori: "https://privacyportal.fatergroup.com/pdf/informativa-privacy-contratti.pdf",
    statement: "https://privacyportal.fatergroup.com/pdf/contracs-privacy-statement.pdf"
}
