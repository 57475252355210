import React, {Fragment, useCallback, useState} from 'react';
import DeleteAlert from './DeleteAlert';

export default function DeleteManagement({handleShowDelete}) {

    return (
        <Fragment>
            <div className="cancellation-box">
                <p>
                    Se desideri <span>CANCELLARE</span> il tuo account e non
                    usufruire più dei relativi servizi clicca sul bottone sottostante.
                </p>
                <button onClick={handleShowDelete}
                        className="cancel-acc-cta"> Cancella account
                </button>
            </div>
        </Fragment>
    );
}
