import {LOGOUT, SET_NEW_PREFERENCE, USER_DATA_SET} from '../stores/ActionType';

const INITIAL_STATE = {
    accountBrands: [],
    brands: [],
    consents: {
        lines: null,
        pampers: null,
    },
    updatedConsent: {}
};

export default function PrivacyReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case USER_DATA_SET:
            const consents = action.payload.consents;
            const newConsents = {};

            ['pampers', 'lines', 'ace', 'specialist', 'tampax', 'hero', 'maternity', 'mfp', 'partners',  'partnersLines']
                .forEach(function(b) {
                    if (typeof consents[b] !== 'undefined') {
                        const structureKeys = Object.keys(consents[b]);
                        const brandStructure = {};
                        structureKeys.forEach(function(ks) {
                            const consentsKeys = Object.keys(consents[b][ks]);
                            consentsKeys.forEach(function(kc) {
                                // TODO: hide
                                if (kc !== 'privacy_dati_sanitari') {
                                    brandStructure[`${b}|${ks}|${kc}`] = consents[b][ks][kc];
                                }
                            });
                        });
                        if (b === 'pampers') {
                            // if (typeof brandStructure['pampers|1|privacy_marketing_email']!== 'undefined' &&
                            //     typeof brandStructure['pampers|1|privacy_marketing_posta']!== 'undefined' &&
                            //     typeof brandStructure['pampers|1|privacy_marketing_telefono']!== 'undefined' &&
                            //     brandStructure['pampers|1|privacy_marketing_email'].value === brandStructure['pampers|1|privacy_marketing_posta'].value &&
                            //     brandStructure['pampers|1|privacy_marketing_email'].value === brandStructure['pampers|1|privacy_marketing_telefono'].value) {
                            //     // brandStructure['pampers|1|privacy_marketing'] = brandStructure['pampers|1|privacy_marketing_email'];
                            //     // delete brandStructure['pampers|1|privacy_marketing_email'];
                            //     // delete brandStructure['pampers|1|privacy_marketing_posta'];
                            //     // delete brandStructure['pampers|1|privacy_marketing_telefono'];
                            //     const temp = brandStructure['pampers|1|privacy_profilazione'];
                            //     delete brandStructure['pampers|1|privacy_profilazione'];
                            //     brandStructure['pampers|1|privacy_profilazione'] = temp;
                            // }
                            if (typeof brandStructure['pampers|1|privacy_marketing_email']!== 'undefined') {
                                delete brandStructure['pampers|1|privacy_altri_brand_marketing'];
                            }
                            if (typeof brandStructure['pampers|1|privacy_altri_brand_profilazione']!== 'undefined') {
                                delete brandStructure['pampers|1|privacy_altri_brand_profilazione'];
                            }


                            if (typeof brandStructure['pampers|14|privacy_marketing_email']!== 'undefined' &&
                                typeof brandStructure['pampers|14|privacy_marketing_posta']!== 'undefined' &&
                                typeof brandStructure['pampers|14|privacy_marketing_telefono']!== 'undefined' &&
                                brandStructure['pampers|14|privacy_marketing_email'].value === brandStructure['pampers|14|privacy_marketing_posta'].value &&
                                brandStructure['pampers|14|privacy_marketing_email'].value === brandStructure['pampers|14|privacy_marketing_telefono'].value) {
                                // brandStructure['pampers|14|privacy_marketing'] = brandStructure['pampers|14|privacy_marketing_email'];
                                delete brandStructure['pampers|14|privacy_marketing_email'];
                                delete brandStructure['pampers|14|privacy_marketing_posta'];
                                delete brandStructure['pampers|14|privacy_marketing_telefono'];
                                const temp = brandStructure['pampers|14|privacy_profilazione'];
                                delete brandStructure['pampers|14|privacy_profilazione'];
                                // brandStructure['pampers|14|privacy_profilazione'] = temp;
                            }
                            const temp = brandStructure['pampers|1|privacy_gravidanza'];
                            if (typeof temp !== 'undefined') {
                                // console.log(temp);
                                delete brandStructure['pampers|1|privacy_gravidanza'];
                                if (temp.value !== '') {
                                    brandStructure['pampers|1|privacy_gravidanza'] = temp;
                                }
                            }
                            [
                                'ext_profilazione_sub'
                            ].forEach(function(k) {
                                const temp = brandStructure[`pampers|1|${k}`];
                                if ((typeof temp !== 'undefined')) {
                                    delete brandStructure[`pampers|1|${k}`];
                                }
                                const temp1 = brandStructure[`pampers|5|${k}`];
                                if (typeof temp1 !== 'undefined') {
                                    delete brandStructure[`pampers|5|${k}`];
                                }
                            });

                            if (typeof brandStructure['pampers|3|privacy_dati_sanitari']!== 'undefined') {
                                const temp = brandStructure['pampers|3|privacy_dati_sanitari'];
                                delete brandStructure['pampers|3|privacy_dati_sanitari'];
                                brandStructure['pampers|3|privacy_dati_sanitari'] = temp;
                            }
                        }
                        if (b === 'lines') {
                        //     if (typeof brandStructure['lines|1|privacy_marketing_email']!== 'undefined' &&
                        //     typeof brandStructure['lines|1|privacy_marketing_posta']!== 'undefined' &&
                        //     typeof brandStructure['lines|1|privacy_marketing_telefono']!== 'undefined' &&
                        //         brandStructure['lines|1|privacy_marketing_email'].value === brandStructure['lines|1|privacy_marketing_posta'].value &&
                        //         brandStructure['lines|1|privacy_marketing_email'].value === brandStructure['lines|1|privacy_marketing_telefono'].value) {
                        //         brandStructure['lines|1|privacy_marketing'] = brandStructure['lines|1|privacy_marketing_email'];
                        //         delete brandStructure['lines|1|privacy_marketing_email'];
                        //         delete brandStructure['lines|1|privacy_marketing_posta'];
                        //         delete brandStructure['lines|1|privacy_marketing_telefono'];
                        //         const temp = brandStructure['lines|1|privacy_profilazione'];
                        //         delete brandStructure['lines|1|privacy_profilazione'];
                        //         brandStructure['lines|1|privacy_profilazione'] = temp;
                        //     }
                            delete brandStructure['lines|1|privacy_marketing_email']
                            delete brandStructure['lines|2|reminder_marketing']
                        }
                        newConsents[b] = brandStructure;
                    }
                });
            return {
                ...state,
                consents: newConsents,
                accountBrands: action.payload.brands,
                brands: Object.keys(newConsents),
                updatedConsent: {},
            };
        case SET_NEW_PREFERENCE:
            return {
                ...state,
                updatedConsent: {
                    ...state.updatedConsent,
                    [action.payload.consentKey]: action.payload.val
                }
            };
        case LOGOUT:
            return {
                ...INITIAL_STATE,
            };
        default:
            return state;
    }
}
