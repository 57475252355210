import classNames from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import Spinner from 'react-activity/dist/Spinner';
import {useDispatch} from 'react-redux';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {useSanctum} from 'react-sanctum';
import Errors from '../../constants/Errors';
import iconAlert from '../../img/icon-alert.svg';
import iconShowPw from '../../img/icon-show.svg';
import {otpAuth} from '../../stores/AppAction';

export default function LoginWithCredentials() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [showPsw, setShowPsw] = useState(false);
    const [loginError, setLoginError] = useState(null);
    const {search} = useLocation();
    const param = new URLSearchParams(search)

    const {signIn, checkAuthentication} = useSanctum();

    useEffect(() => {
        if (param.get('otp')) {
            dispatch(otpAuth(param.get('otp')))
                .then(() => {
                    console.log('reload');
                    window.location.reload();
                })
                .catch((e) => {
                    console.log('replace');
                    history.replace('/');
                });
        }
    },[search])

    const handleAccess = useCallback((e) => {
        e.preventDefault();

        setLoading(true);
        signIn(email, password, false)
            .then(function() {
                setLoading(false);
            }).catch(function(a, b, c) {
            const errorResponse = a.response;
            if (errorResponse.status === 422) {
                const errors = errorResponse.data.errors;
                if (typeof errors.email !== 'undefined') {
                    setLoginError(Errors.emailError.title);
                } else {
                    setLoginError(Errors.pswError.title);
                }
            } else {
                setLoginError(Errors.pswError.title);
            }
            setLoading(false);
        });
    }, [signIn, setLoading, email, password]);

    const handleChangeEmail = useCallback((e) => {
        setLoginError('');
        setEmail(e.target.value);
    }, [setEmail, setLoginError]);

    const handleChangePassword = useCallback((e) => {
        setLoginError('');
        setPassword(e.target.value);
    }, [setPassword, setLoginError]);

    const handleShowHidePsw = useCallback((e) => {
        setShowPsw(!showPsw);
    }, [setShowPsw, showPsw]);

    return (
        <form onSubmit={handleAccess} action="#"
              id="gestione-privacy-login"
              className="form"
              autoComplete={'off'}
        >
            <input
                className={classNames({
                    'error-input-active': loginError ===
                        Errors.emailError.title,
                })}
                name="email"
                type="text"
                value={email}
                placeholder="e-mail"
                onChange={handleChangeEmail}
                autoComplete={'0'}
                tabIndex={1}
                required
            />
            <span className={classNames('error-txt', {
                'error-txt-active': loginError === Errors.emailError.title,
            })}>
                <img src={iconAlert} className="icon-alert" alt="Alert icon" />
                {Errors.emailError.title}
            </span>
            <div className="input-psw">
                <input className={classNames('password-field', {
                    'error-input-active': loginError === Errors.pswError.title,
                })}
                       tabIndex={2}
                       type={showPsw ? 'text' : 'password'}
                       value={password}
                       onChange={handleChangePassword}
                       id="psw"
                       name="password"
                       minLength="8"
                       placeholder="password"
                       required
                />
                <img src={iconShowPw}
                     className={classNames('icon-show-psw', {
                         active: showPsw,
                     })}
                     alt={'Visualizza la password'}
                     onClick={handleShowHidePsw}
                />
            </div>
            <span className={classNames('error-txt', {
                'error-txt-active': loginError === Errors.pswError.title,
            })}>
                <img src={iconAlert} className="icon-alert" alt="Alert icon" />
                {Errors.pswError.title}
            </span>
            {loading && (
                <div className={'spin'}>
                    <Spinner size={1} className={'rai-spinner white'} color="#ffffff" animating speed={0.5}
                             style={{height: '30px', width: '30px'}} />
                </div>
            )}
            {!loading &&
            <input tabIndex={3} type={'submit'} value="Accedi"
                   className="main-btn" />
            }
        </form>
    );
}
