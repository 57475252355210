import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {Sanctum} from 'react-sanctum';
import PrivacyPortal from './navigation/PrivacyPortal';
import reportWebVitals from './reportWebVitals';
import './scss/main.scss';
import createStore from './stores';
import {sanctumConfig} from './api/Api';

const {store} = createStore();

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <Sanctum config={sanctumConfig} >
                <PrivacyPortal />
            </Sanctum>
        </Provider>
    </React.StrictMode>
    , document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
