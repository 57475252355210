import {
    ACCEPT_BRAND,
    ACCEPT_TERMS,
    CHECK_USER_AUTH,
    DELETE_ACCOUNT,
    EMAIL_VALUE,
    GET_PREFERENCES,
    INIT,
    LOADING_UPDATE,
    LOGIN,
    LOGOUT,
    PASSWORD_VALUE,
    PREFERENCES_MODIFIED,
    SEND_LINK,
    SEND_LINK_EMAIL,
    SEND_NEW_PREFERENCES,
    SET_ACTIVE_BRAND,
    TOGGLE_ACCEPT_TERMS_MODAL,
    TOGGLE_NOT_EDITABLE_TERMS_MODAL,
    USER_DATA_SET,
} from '../stores/ActionType';
import {loadingUpdate} from '../stores/AppAction';

const INITIAL_STATE = {
    loaded: false,
    email: '',
    password: '',
    userLogged: true,
    loadingLogin: false,
    loginError: false,
    sendLinkEmail: '',
    linkSended: false,
    loadingLink: false,
    sendError: false,
    deleteAccount: false,
    loadingDeleteAccount: false,
    loadingPreferences: false,
    preferences: null,
    preferencesModified:false,
    loadingUpdate:false,

    activeBrand: null,
    acceptedTerms: false,
    showModalAcceptedTerms: false,
    showModalNotEditableConsent: false,
    brandsAccepted: [],
};

export default function AppReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case `${INIT}_FULFILLED`:
            return {
                ...state,
            };
        case EMAIL_VALUE:
            return {
                ...state,
                email: action.payload,
            };
        case PASSWORD_VALUE:
            return {
                ...state,
                password: action.payload,
            };
        case `${LOGIN}_FULFILLED`:
            return {
                ...state,
                userLogged: action.payload,
                loadingLogin: false,
            };
        case `${LOGIN}_PENDING`:
            return {
                ...state,
                loadingLogin: true,
            };
        case `${LOGIN}_REJECTED`:
            return {
                ...state,
                userLogged: false,
                loadingLogin: false,
                loginError: action.payload.error.response.data.errors.password[0],
            };
        case SEND_LINK_EMAIL:
            return {
                ...state,
                sendLinkEmail: action.payload,
            };
        case `${SEND_LINK}_FULFILLED`:
            return {
                ...state,
                linkSended: true,
                loadingLink: false,
            };
        case `${SEND_LINK}_PENDING`:
            return {
                ...state,
                loadingLink: true,
            };
        case `${SEND_LINK}_REJECTED`:
            return {
                ...state,
                loadingLink: false,
                linkSended: false,
                sendError: action.payload.status,
            };
        case `${CHECK_USER_AUTH}_REJECTED`:
            return {
                ...INITIAL_STATE,
            };
        case LOGOUT:
            return {
                ...INITIAL_STATE,
            };
        case `${DELETE_ACCOUNT}_PENDING`:
            return {
                ...state,
                loadingDeleteAccount: true,
            };
        case `${DELETE_ACCOUNT}_REJECTED`:
            return {
                ...state,
                loadingDeleteAccount: false,
            };
        case `${DELETE_ACCOUNT}_FULFILLED`:
            return {
                ...state,
                deleteAccount: true,
                loadingDeleteAccount: false,
            };
        case ACCEPT_TERMS:
            return {
                ...state,
                acceptedTerms: action.payload,
            };
        case LOADING_UPDATE:
            return {
                ...state,
                loadingUpdate: action.payload,
            };
        case ACCEPT_BRAND:
            return {
                ...state,
                brandsAccepted: [
                    ...state.brandsAccepted,
                    ...action.payload
                ],
            };
        case `${GET_PREFERENCES}_PENDING`:
            return {
                ...state,
                loadingPreferences: true,
            };
        case `${GET_PREFERENCES}_FULFILLED`:
            return {
                ...state,
                preferences: action.payload,
                loadingPreferences: false,
            };
        case USER_DATA_SET:
            return {
                ...state,
                activeBrand: state.activeBrand || (Object.keys(action.payload.consents)[0] ?? null),
            };
        case PREFERENCES_MODIFIED:
            return {
                ...state,
                preferencesModified: action.payload,
            };
        case SET_ACTIVE_BRAND:
            return {
                ...state,
                activeBrand: action.payload,
            };
        case TOGGLE_ACCEPT_TERMS_MODAL:
            return {
                ...state,
                showModalAcceptedTerms: !state.showModalAcceptedTerms,
            };
        case TOGGLE_NOT_EDITABLE_TERMS_MODAL:
            return {
                ...state,
                showModalNotEditableConsent: !state.showModalNotEditableConsent,
            };
        default:
            return state;
    }
}
