import React, {useCallback, useState} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {useDispatch, useSelector} from 'react-redux';
import {sAcceptedTerms, sPreferences} from '../../reducers/selectors';
import PrivacySettings from '../PrivacySettings';
import BrandsTabBar from './BrandsTabBar';

export default function PrivacyManagement() {

    const [showDelete, setShowDelete] = useState(false)

    return (
        <div className="settings-section">
            <div className="description-text">
                <h2>MODIFICA PREFERENZE PRIVACY</h2>
                <p>
                    Per modificare le tue preferenze privacy
                    clicca su <strong>MODIFICA</strong> in basso,
                    conferma di aver letto la
                    nostra informativa privacy e imposta le nuove preferenze per ogni
                    Brand. <strong>Al termine della modifica clicca
                    su <span style={{textTransform: 'underline'}}>SALVA</span> per impostare le
                    nuove preferenze.</strong>
                    <br /> Riceverai un'e-mail di conferma
                    dell’operazione effettuata.
                </p>
            </div>
            <BrandsTabBar />
            <hr />
            <PrivacySettings />
        </div>
    );
}
