import React, {Fragment, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {sAcceptedTerms, sNewPreferences} from '../../reducers/selectors';
import {setNewPreference} from '../../stores/AppAction';
import SwitchButton from '../SwitchButton';

export default function Consent({consentKey, text, consent, acceptedTerms, disabledCallback, notEditableCallback}) {
    const [b, ks, kc] = consentKey.split('|');
    const newPref = useSelector(sNewPreferences);
    // const acceptedTerms = useSelector(sAcceptedTerms);
    const dispatch = useDispatch();

    const value = newPref[consentKey] || (consent.value === 'y'? 'on': 'off');

    const updateConsent = useCallback(function(val) {
        dispatch(setNewPreference(consentKey, val));
    }, [consentKey]);


    return (
        <Fragment>
            <div className="setting-item">
                <p>{text}</p>

                <SwitchButton disabled={!acceptedTerms || !consent.editable}
                              value={value}
                              id={`btn-${consentKey}`}
                              nameForm={`setting-${b}`}
                              callback={updateConsent}
                              disabledCallback={!acceptedTerms ? disabledCallback : notEditableCallback}
                />
            </div>
            <hr />
        </Fragment>
    );
}
